import React from 'react';
import { Container } from './styled';

function Footer() {
  return (
    <Container>
      <p>Elodie FRAPPAT © 2021</p>
      <p>Tous droits réservés</p>
    </Container>
  );
}

export default Footer;
